

<script>

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import {search_accounting_gl} from '@/api/misc'

export default {


    emits: ['onSelected'],

    props: {
       

        input_class : {
            type : Object,
            default: () => ({}),
        },

        value : {
            type : String,
            default: () =>'',
        }
    },

    data() {
        return {
            
            gl_number : this.value,
            searched_data : []
        }
        
    },

    methods: {
        onSearched(new_value) {
            if (!new_value || new_value == '') {
                return;
            }
            search_accounting_gl(new_value).then(res => {
                this.searched_data = []
                res.data.map((d) => {
                    this.searched_data.push({
                        label: `${d.account}(${d.sign}) (${d.description})`,
                        value: d.account,
                        gl   : d
                    })
                })
            })
        },

        onHit(evt) {
            this.$emit('onSelected', evt)
            this.$emit('input', evt.value)
        },

        setValue(val) {
            this.$refs['native_typeahead'].inputValue =val
        }
    },

    created() {
        this.gl_number = new String(this.value ? this.value : '')
    },

    components: {
        VueBootstrapTypeahead
    },

    watch: {
        gl_number: function(new_value) { this.onSearched(new_value) }
    },


}
</script>

<template>
     <vue-bootstrap-typeahead 
        ref="native_typeahead"
        v-model="gl_number"
        :value ="value"
        :data="searched_data"
        :inputClass="input_class"
        :serializer="s => s.label"
        :foramterDisplay="s => s.label"
        @hit="onHit($event)"
        autocomplete="off"
        />
</template>