<script>


/**
 * Basic Info Edit component
 */

 import simplebar from "simplebar-vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  required,
} from "vuelidate/lib/validators";

import GLAutoComplete from "@/components/gl-auto-complete-input.vue";
import DealAutoComplete from "@/components/deal-auto-complete-input.vue";


import {financial} from '@/api/misc'


export default {

    emits: ['confirm', 'cancel'],

    props: ['gl_entries'],
    components: {
        flatPickr,
        simplebar,
        GLAutoComplete,
        DealAutoComplete
    },

    validations: {
        post_date: { required },
    },

    

    data() {
      
        return {
            submitted : false,
            listingData : [],
            gl_items : [],
            gl_account : '',
            post_date : '',
            amount : '',
            show_error : false,
            description: '',
            ref_obj    : null,
        }
       
    },
    methods: {
        formSubmit() {
            this.submitted = true;     
          
            let data = {
                ref       : this.ref_obj,
                ref_type  : 'DEAL',
                gls       : this.listingData,
                remark    : this.description,
                post_date : this.post_date,
            }  
            this.$v.$touch();
            if (this.$v.$error == false) {  
                this.$emit('confirm', data)
            }
            
            
        },


        add_gl_entry() {
            this.listingData.push({
                gl_account : this.gl_account,
                amount     : Number(this.amount)
            })

            let amount = this.listingData.reduce((a, c) => a +  Number(c.amount), 0)
            if (financial(amount, 2) == financial(0, 2)) {
                this.show_error = false
            } else {
                this.show_error = true
            }
        },

        removeEntry(data) {
            this.listingData.splice(data.index, 1)
        },

        onGLSelected() {

        },

        onRefSelected(evt) {
            //only DEAL
            this.ref_obj = {
                ref_id   : evt.deal.id,
                ref_type : 'DEAL',
                ref_sid  : evt.deal.order_deal_id
            }
        },

        onRefCleared() {
            this.ref_obj = null
        },

    
    },

    created() {
     
       
    },

    mounted() {

       
      

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">

                <div class="row">
                    <div class="col-md-4">
                        <label>Post Date </label>
                        <flat-pickr
                        v-model="post_date"
                        placeholder="Select a date"
                        class="form-control"
                        :class="{
                                    'is-invalid':  $v.post_date.$error,
                                }"
                        
                        ></flat-pickr>
                        <div v-if=" $v.post_date.$error" class="invalid-feedback">
                            <span v-if="!$v.post_date.required">This value is required.</span>
                        </div>
                    </div>

                    <div class="col-md-8">
                        <label>description </label>
                        <input type="text" class="form-control" v-model="description" />
                    </div>
                    
                </div>

                <div class="row mt-3" ></div>
                <div class="row mt-3">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">Account</label>
                            <GLAutoComplete v-model="gl_account" ref="gl_account" @onSelected="onGLSelected($event, 'gl')"/>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for="sign_choick">Amount</label>
                                <input id="validationCustom01" v-model="amount" type="text"
                                class="form-control" placeholder="" value=""  />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for="sign_choick">Ref</label>
                                <DealAutoComplete @onSelected="onRefSelected" @onCleared="onRefCleared"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="mb-3 mt-4">
                            <b-button variant="primary" type="button" @click="add_gl_entry">Add Entry</b-button>
                        </div>
                    </div>

                  
                </div>
                 <!-- end row-->

               

                <simplebar style="max-height: 40dvh;">
                <div class="table-responsive mt-3" >
                    <div class="table align-middle table-nowrap">
                        <b-table
                        :items="listingData"
                        :fields="['Account', 'Amount', 'Remove']"
                        responsive="sm"
                        :per-page="10"
                        class="table-check"
                        >
                        <template #cell(Account)="data"> 
                            <div class="row icon-demo-content-10"><div class="col-xl-3 col-lg-4 col-sm-6">{{ data.item.gl_account}}</div></div>
                        </template>
                        <template #cell(Amount)="data">
                            <div class="row icon-demo-content-10"><div class="col-xl-3 col-lg-4 col-sm-6">${{ data.item.amount.toLocaleString()}}</div></div>
                        </template>
                      
                        <template #cell(Remove)="data">
                            <div class="row icon-demo-content-10"><div class="col-xl-3 col-lg-4 col-sm-6" @click="removeEntry(data)"><i class="uil-times font-size-4"></i></div></div> 
                        </template>
                       
                        
                        </b-table>
                    </div>
                </div>
                </simplebar>
                <!-- end table responsive -->


                <div class="row">
                    <div class="col-md-12">
                        <b-alert show variant="danger" v-if="show_error" >The Balance is not 0</b-alert >
                    </div>
                </div>
                
                

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-5">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                           
                            <b-button  variant="primary" type="submit">Submit</b-button>
                        </div>
                    </div>
                    <div class="col-md-3">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>