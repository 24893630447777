<script>
//import { required } from "vuelidate/lib/validators";

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {getAccountingGLApi} from '@/api/accounting/query'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import FsGlEditDialog from './components/add_gl_entry_dialog.vue'
import GLEntriesUploadDiloag from './components/upload_gl_entries_dialog.vue'
import { financial } from '@/api/misc'

/**
 * User list component
 */
export default {
  page: {
    title: "General Ledger List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      listingData: [],
      title: "General Ledger List",
      items: [
        {
          text: "Setting",
          href: "/",
        },
        {
          text: "General Ledger",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 500,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [
       
        {
          key: "Account",
          sortable: true,
        },
       
        {
          key: "Amount",
          sortable: true,
        },
        {key : "EndingBalance"},
        {
          key: "Date",
          sortable: true,
        },
        {
          key: "Description",
          sortable: true,
        },
      ],
      submitted: false,
      searched_str : '',
      start_date : '',
      end_date : '',
      open_balance : 0,
     
      upload_gl_entries: []
    };
  },
  components: {
    Layout,
    PageHeader,
    FsGlEditDialog,
    flatPickr,
    GLEntriesUploadDiloag
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {
    
  },
  mounted() {
  
  },
  methods: {

    f(v, d = 2) {
      return financial(v,d)
    },

    queryList() {

      if (!this.searched_str || !this.start_date) {
        this.$alertify.error("Please Input Filter Condition ");
        return;
      }
      let data = {
        gl_account : this.searched_str,
        start_date : this.start_date,
        page : {
          page_no : this.currentPage,
          page_size : this.perPage
        }
      }

      if (this.end_date) {
        data.end_date  = this.end_date
      }
      getAccountingGLApi().entry_query(data).then(res => {
        if (res.errCode == 0) {
          this.listingData = []
          this.open_balance = res.data.open_balance
          res.data.result.map(e => this.listingData.push(e))
          this.totalRows = res.page.total
        }
      })
    },


    exportGLQuery() {
      if (!this.searched_str || !this.start_date) {
        this.$alertify.error("Please Input Filter Condition ");
        return;
      }
      let data = {
        gl_account : this.searched_str,
        start_date : this.start_date,
        page : {
          page_no : this.currentPage,
          page_size : this.perPage
        }
      }

      if (this.end_date) {
        data.end_date  = this.end_date
      }
      getAccountingGLApi().export_je_list(data).then(res => {
        if (res.errCode == 0) {
         window.open(res.data.url, '_blank')
        }
      })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },
   

    gl_save(gl_data) {
     
      getAccountingGLApi().add_regular_entry(gl_data).then((res)=> {
        if (res.errCode == 0) {
          this.$bvModal.hide('modal-gl-edit-dialog')
        } 
     })
    
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
    },

    onGlEntriesUploaded(entries) {
      this.upload_gl_entries = entries
      this.$bvModal.hide('modal-gl-entries-upload-dialog')
      this.$bvModal.show('modal-gl-edit-dialog')
    }

  },
  validations: {
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-1">
                <label>GL </label>
                <input class="form-control" v-model="searched_str" placeholder="GL Number"  @keyup.enter="queryList"/>
               
              </div>

              <div class="col-md-2">
                <label>Start Date </label>
                <flat-pickr  v-model="start_date"  placeholder="Select a date"  class="form-control"  ></flat-pickr>
              </div>
              <div class="col-md-2">
                <label>End Date </label>
                <flat-pickr  v-model="end_date"  placeholder="Select a date"  class="form-control"  ></flat-pickr>
              </div>
              <div class="col-md-7 gap-2 mb-3 gap-2 mt-4">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                >
                <b-button variant="primary" @click="queryList">Query</b-button>
                <b-button variant="primary" v-b-modal.modal-gl-edit-dialog>Add GL Entry</b-button>
                <b-button variant="primary" v-b-modal.modal-gl-entries-upload-dialog>Import GL Entry</b-button>
                <b-button variant="primary" @click="exportGLQuery">Export</b-button>
                </div>
              </div>



              <b-modal centerd :id="'modal-gl-edit-dialog'" title="General Ledger" size="lg" hide-footer>
                <FsGlEditDialog  :gl_entries="upload_gl_entries" @cancel="$bvModal.hide('modal-gl-edit-dialog')" @confirm="gl_save"  />
              </b-modal>
              <b-modal centerd :id="'modal-gl-entries-upload-dialog'" title="General Ledger" size="lg" hide-footer>
                <GLEntriesUploadDiloag  @cancel="$bvModal.hide('modal-gl-entries-upload-dialog')" @confirm="onGlEntriesUploaded"  />
              </b-modal>
              
            </div>
            <!-- end row -->

            <div class="row">

              <div class="col-md-4">
                <label>Open Balance : $ {{ Number(this.open_balance).toLocaleString() }} </label>
              
              </div>
            </div>
            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="listingData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  class="table-check"
                
                >
                  <template #cell(Account)="data"> 
                    {{ data.item.gl_account}}
                  </template>
                  <template #cell(Amount)="data">
                    ${{ f(data.item.amount).toLocaleString()}}
                  </template>

                  <template #cell(EndingBalance)="data">
                    ${{ f(data.item.ending_balance).toLocaleString()}}
                  </template>
                  <template #cell(Date)="data">
                    {{ data.item.entry_date}}
                  </template>
                  <template #cell(Description)="data">
                    {{ data.item.description }}
                  </template>
                
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <!-- pagination -->
                      <b-pagination
                        class="pagination-rounded"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @change="pageChange"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
