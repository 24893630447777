<script>


/**
 * Deposit Edit component
 */




import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";


export default {

    emits: ['confirm', 'cancel'],


    components: {
        vueDropzone: vue2Dropzone,
    },
    

    data() {
        return {
           
            dropzoneOptions: {
                url: "/adm/accounting/gl/upload_gl_file",
                thumbnailWidth: 150,
                maxFilesize: 10,
                maxFiles: 1,
                paramName: 'gl_entries'
            },

            gl_entries: []

        }
    },

    methods: {

        formSubmit() {
          
        },

        fileUploaded(ev, resp) {
            console.log("uploaded" , ev.upload, ev, resp)
            if (resp.errCode == 0) {
                this.$emit('confirm', resp.data)
            } else {
                this.$alertify.message("Failed to Upload " +resp.errCode);
            }
        },

    },

    created() {
      
    },

    mounted() {

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">


                <div class="row">
                    <div class="col-xl-12">
                        <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions"   @vdropzone-success="fileUploaded">
                            <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop GL Entries File to Here.</h4>
                            </div>
                        </vue-dropzone>
                    </div>

                </div>
                <!-- end row-->


                <div class="row mt-3">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Submit</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>