


import request from '@/utils/request';


class AccountingGLApi {
    entry_query = (data) => {
        return request({
            url: '/adm/accounting/query/gl_entry_query',
            method: 'post',
            data
        });	
    }

    ref_query = (data) => {
        return request({
            url: '/adm/accounting/query/gl_ref_query',
            method: 'post',
            data
        });	
    }

    ref_gl_detail = (data) => {
        return request({
            url: '/adm/accounting/query/ref_gl_detail_query',
            method: 'post',
            data
        });	
    }

    add_regular_entry = (data) => {
        return request({
            url: '/adm/accounting/gl/add_gl_entry',
            method: 'post',
            data
        });	
    }

    gl_list = (data) => {
        return request({
            url: '/adm/accounting/query/gl_list',
            method: 'post', 
            data
        });	
    }

    export_je_list = (data) => {
        return request({
            url: '/adm/accounting/query/export_gl_entry_list',
            method: 'post', 
            data
        });	
    }

    fs_line_list = (data) => {
        return request({
            url: '/adm/accounting/query/fs_line_list',
            method: 'post', 
            data
        });	
    }

    gl_update = (data) => {
        return request({
            url: '/adm/accounting/gl/gl_update',
            method: 'post', 
            data
        });	
    }


    gl_new = (data) => {
        return request({
            url: '/adm/accounting/gl/add_new_gl',
            method: 'post', 
            data
        });	
    }



    financial_statement_query = (data) => {
        return request({
            url: '/adm/accounting/financialstatment/fs_statement',
            method: 'post', 
            data
        });	
    }

   
}



let _api = null;


const getAccountingGLApi = () => {
    if (!_api) {
        _api = new AccountingGLApi();
    }
    return _api;
}




export { getAccountingGLApi};

